/**
 * Create logic for ticking the online verification method when
 * clicking in the doccheck login form.
 */
function initDocCheckForm() {
  const doccheckUsernameField = document.getElementById("id_username");
  const doccheckAuthCheckbox = document.getElementById("id_check_doccheck");
  const offlineAuthCheckbox = document.getElementById("id_check_offline");

  if (!doccheckUsernameField) return; // wrong page

  const choices = [doccheckAuthCheckbox, offlineAuthCheckbox];

  function activateChoice(input) {
    if (!input.checked) return;
    input.parentElement.parentElement.classList.toggle(
      "choice--active",
      input.checked
    );
    choices.forEach((other) => {
      if (other !== input) {
        other.parentElement.parentElement.classList.remove("choice--active");
      }
    });
  }

  choices.forEach((input) => {
    input.addEventListener("change", () => {
      activateChoice(input);
    });
    activateChoice(input);
  });

  // Check Doccheck Authentication Method, if User fills out Login form
  doccheckUsernameField.addEventListener("click", () => {
    doccheckAuthCheckbox.checked = true;
    activateChoice(doccheckAuthCheckbox);
  });

  // Listen for htmx insertions.
  document.body.addEventListener("htmx:load", function (event) {
    // If the doccheck success overlay is found, attach events to
    // close it with the corresponding button.
    const resetButton = event.detail.elt.querySelector(
      "[data-id='reset-doccheck-form']"
    );
    if (!resetButton) return;

    const successFrame = event.detail.elt.querySelector(".login-success");
    if (!successFrame) return;

    resetButton.addEventListener("click", () => {
      successFrame.parentElement.removeChild(successFrame);
      // Clear session at server side
      fetch("/doccheck/reset/", { method: "POST" });
    });
  });
}

/**
 * Conditionally show and hide fields
 *
 * HTML Syntax is:
 *
 * <div class="verify verify--hidden" data-on="OT" data-src="id_specialization">
 *       ...
 * </div>
 *
 * Which translates to:
 *
 * When the input with the id "id_specialization" has the value "OT"
 *  <=> show the container.
 *
 * It accepts
 *
 *  containers: An object mapping functions which should return a boolean and
 *      are evaluated and added to the condition when to show the container.
 *
 *      Eg:
 *          {
 *              auth: (input, targetValue) => {
 *                   return needsAuth(input.value);
 *              },
 *           }
 *
 */
function initConditionalContainers(conditions) {
  document
    .querySelectorAll("[data-action='verify']")
    .forEach((conditionalElement) => {
      document
        .querySelectorAll(
          `input[name='${conditionalElement.dataset["src"]}'], select[name='${conditionalElement.dataset["src"]}']`
        )
        .forEach((input) => {
          const targetValues = conditionalElement.dataset["on"].split(",");

          function shouldShow(input, targetValues) {
            if (input.type === "radio" && !input.checked) {
              return false;
            }
            for (const targetValue of targetValues) {
              let should = input.value == targetValue;
              if (targetValue === "__ANY__" && input.value) {
                should = true;
              }
              if (conditions[targetValue]) {
                should = should && conditions[targetValue](input, targetValue);
              }

              // Early return if one of the conditions evaluates to true
              if (should) {
                return true;
              }
            }
            return false;
          }

          if (shouldShow(input, targetValues)) {
            conditionalElement.classList.remove("verify--hidden");
          }

          input.addEventListener("click", (event) => {
            const show = shouldShow(event.target, targetValues);
            conditionalElement.classList.toggle("verify--hidden", !show);
          });
        });
    });
}

/**
 * Show/Hide extra form fields depending on the chosen profession. Eg.
 *
 * Eg: Show the Doccheck form login form for doctors.
 */
function initProfessionField() {
  const professionsWrapper = document.getElementById("professions-w-auth");

  if (!professionsWrapper) return; // wrong page

  const professions = JSON.parse(professionsWrapper.textContent);

  function needsAuth(value) {
    return professions.includes(value);
  }

  initConditionalContainers({
    auth: (input) => {
      return needsAuth(input.value);
    },
  });
}

function initDeleteAction() {
  document.querySelectorAll('[data-onclick="delete"]').forEach((el) => {
    el.addEventListener("click", () => {
      el.parentElement.removeChild(el);
    });
  });
}

function initActionShow() {
  document.querySelectorAll('[data-action="show"]').forEach((el) => {
    console.log(`[name=${el.dataset["src"]}]`);
    const target = document.querySelector(`[name=${el.dataset["src"]}]`);
    target.addEventListener("click", (event) => {
      if (target.checked) {
        el.classList.remove("hidden");
      } else {
        el.classList.add("hidden");
      }
    });
    if (target.checked) {
      el.classList.remove("hidden");
    } else {
      el.classList.add("hidden");
    }
  });
}

function initPasswortToggle() {
  document.querySelectorAll('[data-type="password-toggle"]').forEach((el) => {
    const input = document.getElementById(el.dataset["toggle"]);
    el.addEventListener("mousedown", () => {
      input.type = "text";
      el.classList.add("clicked");
    });
    el.addEventListener("mouseup", () => {
      input.type = "password";
      el.classList.remove("clicked");
    });
  });
}

/**
 * Toggles the information about how to enter a packstation as address
 * into the self-service/address settings.
 */
function packstationToggle() {
  const previewId = "packstation-notes-preview";
  const contentToggleId = "packstation-notes-preview-toggle";
  const contentId = "packstation-notes";

  const previewElement = document.getElementById(previewId);
  if (!previewElement) return;
  const contentToggleElement = document.getElementById(contentToggleId);
  if (!contentToggleElement) return;
  const contentElement = document.getElementById(contentId);
  if (!contentElement) return;

  contentToggleElement.addEventListener("click", (event) => {
    event.preventDefault();
    previewElement.classList.add("hidden");
    contentElement.classList.remove("hidden");
  });
}

document.addEventListener("DOMContentLoaded", () => {
  initActionShow();
  initDocCheckForm();
  initProfessionField();
  initDeleteAction();
  initPasswortToggle();
  packstationToggle();

  /** Hide show other specializations field on click at label */
  const label = document.querySelector(
    ".form-field-other_specializations__label"
  );
  if (label) {
    label.addEventListener("click", (event) => {
      document
        .querySelector(".form-field-other_specializations > .hide-wrapper")
        .classList.toggle("hidden");
    });
  }
});
